// AssetFilter.js
import React, { useState } from 'react';
import "./AssetFilter.css"

const AssetFilter = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedField, setSelectedField] = useState('all');

  const handleSearch = () => {
    onSearch({ field: selectedField, term: searchTerm });
  };

  return (
    <div className="asset-filter">
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <select value={selectedField} onChange={(e) => setSelectedField(e.target.value)}>
        <option value="all">All Fields</option>
        <option value="fieldUnit">Field Unit</option>
        <option value="name">Name</option>
        <option value="category">Category</option>
        <option value="date">Date</option>
        <option value="voucherNumber">Voucher Number</option>
        <option value="particulars">Particulars</option>
        <option value="supplierName">Supplier Name</option>
        <option value="billNumber">Bill Number</option>
        <option value="quantity">Quantity</option>
        <option value="rate">Rate</option>
        <option value="tax">Tax</option>
        <option value="transportation">Transportation</option>
        <option value="amount">Amount</option>
        <option value="identification">Identification</option>
        <option value="condition">Condition</option>
      </select>

      <button onClick={handleSearch}>Search</button>
    </div>
  );
};

export default AssetFilter;
