// HomePage.js

import React from 'react'
import { Link } from 'react-router-dom'
import './Homepage.css'

const HomePage = () => {
  return (
    // <div className="home-page">
    //   <header className="hero-section">
    //     <div className="contact-details">
    //       <h1>Welcome to Our Company</h1>
    //       <p>Email: example@email.com</p>
    //       <p>Phone: +1234567890</p>
    //       <p>Address: 123 Main Street, City</p>
    //     </div>
    //   </header>

    //   <section className="cta-section">
    //     <h2>Explore Our Services</h2>
    //     <div className="button-section">
    //       <Link to="/assets">
    //         <button className="home-button">View Assets</button>
    //       </Link>
    //       <Link to="/login">
    //         <button className="home-button">Assets Register</button>
    //       </Link>
    //     </div>
    //   </section>

    //   <section className="content-section">
    //     <h2>Latest Updates</h2>
    //     <p>This is the content of the homepage. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    //   </section>

    //   <footer className="footer-section">
    //     <p>&copy; 2023 Our Company. All rights reserved.</p>
    //   </footer>
    // </div>

    <div className="container">
      <div className="home-con">
        <div className="row">
          <div className="col-12 d-block m-auto">
            <div className="card">
              <h1>Welcome to Our Company</h1>
              <h6>Email: example@email.com</h6>
              <h6>Phone: +1234567890</h6>
              <h6>Address: 123 Main Street, City</h6>
            </div>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-12 d-block m-auto">
            <div className="card">
              <div className="card-body">
                <h2 className="text-center">Explore Our Services</h2>
                <div className="home-btn">
                  <Link to="/assets">
                    <button className="home-button">View Assets</button>
                  </Link>
                  <Link to="/login">
                    <button className="home-button">Assets Register</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-block m-auto">
            <div className="card text-center">
              <div className="card-body">
                <h2>Latest Updates</h2>

                <h6>
                  This is the content of the homepage. Lorem ipsum dolor sit
                  amet, consectetur adipiscing elit.
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-5 footer">
          <div className="col-12 d-block m-auto">
            <div className="card text-center">
              <div className="card-body">
                <h5>&copy; 2023 Our Company. All rights reserved.</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePage
