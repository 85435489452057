// AssetList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AssetFilter from '../../Components/AssetFilter/AssetFilter';
import './AssetList.css';
import { Table } from 'react-bootstrap';

const AssetList = () => {
  const [assets, setAssets] = useState([]);
  const [filteredAssets, setFilteredAssets] = useState([]);

  useEffect(() => {
    axios
      .get('https://afpapi.jcloudwork.com/api/assets')
      .then((response) => {
        setAssets(response.data);
        setFilteredAssets(response.data);
      })
      .catch((error) => {
        console.error('Error fetching assets:', error);
      });
  }, []);

  const handleSearch = ({ field, term }) => {
    if (field === 'all') {
      const filtered = assets.filter((asset) =>
        Object.values(asset).some((value) =>
          value.toString().toLowerCase().includes(term.toLowerCase())
        )
      );
      setFilteredAssets(filtered);
    } else {
      const filtered = assets.filter((asset) =>
        asset[field].toString().toLowerCase().includes(term.toLowerCase())
      );
      setFilteredAssets(filtered);
    }
  };

  return (
    <div className="container-fluid">
      <div className="Assetlist">
        <h2>Asset List</h2>
        
        {/* Render the AssetFilter component */}
        <AssetFilter onSearch={handleSearch} />

        <Table className="table table-bordered rounded-4">
          <thead>
            <tr>
              <th className="assetTableHead">ID</th>
              <th>Field Unit</th>
              <th>Name</th>
              <th>Category</th>
              <th>Date</th>
              <th>Voucher Number</th>
              <th>Particulars</th>
              <th>Supplier Name</th>
              <th>Bill Number</th>
              <th>Quantity</th>
              <th>Rate</th>
              <th>Tax</th>
              <th>Transportation</th>
              <th>Amount</th>
              <th>Identification</th>
              <th>Condition</th>
            </tr>
          </thead>
          <tbody>
            {filteredAssets.map((asset) => (
              <tr key={asset.id}>
                <td>{asset.id}</td>
                <td>{asset.fieldUnit}</td>
                <td>{asset.name}</td>
                <td>{asset.category}</td>
                <td>{asset.date}</td>
                <td>{asset.voucherNumber}</td>
                <td>{asset.particulars}</td>
                <td>{asset.supplierName}</td>
                <td>{asset.billNumber}</td>
                <td>{asset.quantity}</td>
                <td>{asset.rate}</td>
                <td>{asset.tax}</td>
                <td>{asset.transportation}</td>
                <td>{asset.amount}</td>
                <td>{asset.identification}</td>
                <td>{asset.condition}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default AssetList;
