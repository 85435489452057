// MainForm.js

import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import './MainForm.css'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const MainForm = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    step: 1,
    fieldUnit: '',
    name: '',
    category: '',
    date: '',
    voucherNumber: '',
    particulars: '', // Updated to a single string instead of an array
    description: '',
    supplierName: '',
    billNumber: '',
    quantity: '',
    rate: '',
    tax: '',
    transportation: '',
    amount: 0,
    identification: '',
    condition: '',
  })

  const handleNext = () => {
    if (formData.step === 1) {
      if (formData.fieldUnit && formData.name) {
        setFormData({ ...formData, step: 2 })
      } else {
        alert('Please fill in all fields.')
      }
    } else if (formData.step === 2) {
      if (formData.category) {
        setFormData({ ...formData, step: 3 })
      } else {
        alert('Please select a category.')
      }
    }
  }

  useEffect(() => {
    const rate = parseFloat(formData.rate) || 0
    const tax = parseFloat(formData.tax) || 0
    const transportation = parseFloat(formData.transportation) || 0

    const calculatedAmount = rate + tax + transportation
    setFormData((prevData) => ({ ...prevData, amount: calculatedAmount }))
  }, [formData.rate, formData.tax, formData.transportation])

  const handleSubmit = () => {
    axios
      .post('https://afpapi.jcloudwork.com/api/submit-form', formData)
      .then((response) => {
        console.log(response.data)
        toast.success('Form submitted successfully!')
        navigate('/main-form') // Change '/success' to the desired success page
      })
      .catch((error) => {
        console.error('Error submitting form:', error)
        toast.error('Error submitting form. Please try again.')
      })
  }

  const getParticularsOptions = () => {
    switch (formData.category) {
      case 'FURNITURE':
        return ['Table', 'Chair', 'Almirah', 'Book Case', 'Wall Reck', 'Others']
      case 'OFFICE ITEMS':
        return [
          'Refrigerator',
          'Water Purifier',
          'Type Writer',
          'Generator',
          'Others',
        ]
      case 'COMPUTERS':
        return ['Desktop', 'Laptop', 'Tablet', 'Printer', 'Others']
      case 'AUDIO VISUAL':
        return [
          'Camera',
          'Video Camera',
          'Sound System',
          'Overhead Projectors',
          'Others',
        ]
      case 'VEHICLES':
        return ['Jeep', 'Car', 'MotorCycle', 'Truck', 'Others']
      case 'EQUIPMENTS':
        return ['Logger', 'Terrameter', 'Top Cone', 'Wadi', 'Others']
      default:
        return []
    }
  }

  return (
    <div className={`main-form-con step-${formData.step}`}>
      <div className="container">
        <ToastContainer />
        <div className="row justify-content-center ">
          <div className="col-md-8 d-block m-auto  step1-form  ">
            <h2 className="text-login pb-4">Main Form: Step {formData.step}</h2>
            {formData.step === 1 && (
              <div className="">
                <div>
                  <label htmlFor="fieldUnit">Field Unit:</label>
                  <div className="form-group mt-2">
                    <select
                      id="fieldUnit"
                      className="form-control select"
                      value={formData.fieldUnit}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          fieldUnit: e.target.value,
                        })
                      }
                    >
                      <option value="">Select Field Unit</option>
                      <option value="AFPRO HEAD OFFICE">
                        AFPRO HEAD OFFICE
                      </option>
                      <option value="AFPRO REGIONAL OFFICE AHMEDNAGAR">
                        AFPRO REGIONAL OFFICE AHMEDNAGAR
                      </option>
                    </select>
                  </div>
                </div>
                <div>
                  <label htmlFor="name">Name of Person:</label>
                  <div className="form-group"></div>
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    value={formData.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <button
                    className="btn step1-form-btn mt-4"
                    onClick={handleNext}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}

            {formData.step === 2 && (
              <>
                <div className="form-field">
                  <label htmlFor="category">Select Category:</label>
                  <div className="form-group">
                    <select
                      id="category"
                      className="form-control"
                      value={formData.category}
                      onChange={(e) =>
                        setFormData({ ...formData, category: e.target.value })
                      }
                    >
                      <option value="">Select Category</option>
                      <option value="FURNITURE">FURNITURE</option>
                      <option value="OFFICE ITEMS">OFFICE ITEMS</option>
                      <option value="COMPUTERS">COMPUTERS</option>
                      <option value="AUDIO VISUAL">AUDIO VISUAL</option>
                      <option value="VEHICLES">VEHICLES</option>
                      <option value="EQUIPMENTS">EQUIPMENTS</option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <button
                    className="btn step1-form-btn mt-4"
                    onClick={handleNext}
                  >
                    Next
                  </button>
                </div>
              </>
            )}

            <div className="step3-form">
              {formData.step === 3 && (
                <>
                  <div className="row ">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div>
                        <label htmlFor="date">Date:</label>
                        <div className="form-group">
                          <input
                            type="date"
                            id="date"
                            className="form-control"
                            value={formData.date}
                            onChange={(e) =>
                              setFormData({ ...formData, date: e.target.value })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div>
                        <label htmlFor="voucherNumber">Voucher Number:</label>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="voucherNumber"
                            value={formData.voucherNumber}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                voucherNumber: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div>
                        <label htmlFor="particulars">Particulars:</label>
                        <div className="form-group">
                          <select
                            id="particulars"
                            className="form-control"
                            value={formData.particulars}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                particulars: e.target.value,
                              })
                            }
                          >
                            <option value="">Select Particulars</option>
                            {getParticularsOptions().map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div>
                        <label htmlFor="supplierName">Supplier Name:</label>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="supplierName"
                            value={formData.supplierName}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                supplierName: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div>
                        <label htmlFor="billNumber">Bill Number:</label>
                        <div className="form-group">
                          <input
                            type="text"
                            id="billNumber"
                            className="form-control"
                            value={formData.billNumber}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                billNumber: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div>
                        <label htmlFor="quantity">Quantity:</label>
                        <div className="form-group">
                          <input
                            type="number"
                            id="quantity"
                            value={formData.quantity}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                quantity: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div>
                        <label htmlFor="rate">Rate:</label>
                        <div className="form-group">
                          <input
                            type="number"
                            id="rate"
                            className="form-control"
                            value={formData.rate}
                            onChange={(e) =>
                              setFormData({ ...formData, rate: e.target.value })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div>
                        <label htmlFor="tax">Tax:</label>
                        <div className="form-group">
                          <input
                            type="number"
                            id="tax"
                            className="form-control"
                            value={formData.tax}
                            onChange={(e) =>
                              setFormData({ ...formData, tax: e.target.value })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div>
                        <label htmlFor="transportation">
                          Transportation / Other:
                        </label>
                        <div className="form-group">
                          <input
                            type="number"
                            id="transportation"
                            className="form-control"
                            value={formData.transportation}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                transportation: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div>
                        <label>Amount:</label>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="amount"
                            value={formData.amount}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div>
                        <label htmlFor="identification">Identification:</label>
                        <div className="form-group">
                          <input
                            type="text"
                            id="identification"
                            className="form-control"
                            value={formData.identification}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                identification: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div>
                        <label htmlFor="condition">Condition:</label>
                        <div className="form-group">
                          <select
                            id="condition"
                            className="form-control"
                            value={formData.condition}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                condition: e.target.value,
                              })
                            }
                          >
                            <option value="">Select Condition</option>
                            <option value="Good Condition">
                              Good Condition
                            </option>
                            <option value="Running">Running</option>
                            <option value="Condemned">Condemned</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="step3-btn  w-100 flex flex-row justify-content-between align-items-center">
                    <button
                      className="btn step1-form-btn mt-4 mr-5"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                    <button
                      className="btn step1-form-btn mt-4 m1-5"
                      onClick={handleNext}
                    >
                      Next
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <ToastContainer />
      <h2>Main Form: Step {formData.step}</h2>
      {formData.step === 1 && (
        <>
          <div className="form-field">
            <label htmlFor="fieldUnit">Field Unit:</label>
            <select
              id="fieldUnit"
              value={formData.fieldUnit}
              onChange={(e) => setFormData({ ...formData, fieldUnit: e.target.value })}
            >
              <option value="">Select Field Unit</option>
              <option value="AFPRO HEAD OFFICE">AFPRO HEAD OFFICE</option>
              <option value="AFPRO REGIONAL OFFICE AHMEDNAGAR">AFPRO REGIONAL OFFICE AHMEDNAGAR</option>
            </select>
          </div>
          <div className="form-field">
            <label htmlFor="name">Name of Person:</label>
            <input
              type="text"
              id="name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
          </div>
        </>
      )} */}

      {/* {formData.step === 2 && (
        <>
          <div className="form-field">
            <label htmlFor="category">Select Category:</label>
            <select
              id="category"
              value={formData.category}
              onChange={(e) =>
                setFormData({ ...formData, category: e.target.value })
              }
            >
              <option value="">Select Category</option>
              <option value="FURNITURE">FURNITURE</option>
              <option value="OFFICE ITEMS">OFFICE ITEMS</option>
              <option value="COMPUTERS">COMPUTERS</option>
              <option value="AUDIO VISUAL">AUDIO VISUAL</option>
              <option value="VEHICLES">VEHICLES</option>
              <option value="EQUIPMENTS">EQUIPMENTS</option>
            </select>
          </div>
        </>
      )} */}
      {/* 
      {formData.step === 3 && (
        <>
          <div className="form-field">
            <label htmlFor="date">Date:</label>
            <input
              type="date"
              id="date"
              value={formData.date}
              onChange={(e) =>
                setFormData({ ...formData, date: e.target.value })
              }
            />
          </div>

          <div className="form-field">
            <label htmlFor="voucherNumber">Voucher Number:</label>
            <input
              type="text"
              id="voucherNumber"
              value={formData.voucherNumber}
              onChange={(e) =>
                setFormData({ ...formData, voucherNumber: e.target.value })
              }
            />
          </div>

          <div className="form-field">
            <label htmlFor="particulars">Particulars:</label>
            <select
              id="particulars"
              value={formData.particulars}
              onChange={(e) =>
                setFormData({ ...formData, particulars: e.target.value })
              }
            >
              <option value="">Select Particulars</option>
              {getParticularsOptions().map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div className="form-field">
            <label htmlFor="supplierName">Supplier Name:</label>
            <input
              type="text"
              id="supplierName"
              value={formData.supplierName}
              onChange={(e) =>
                setFormData({ ...formData, supplierName: e.target.value })
              }
            />
          </div>

          <div className="form-field">
            <label htmlFor="billNumber">Bill Number:</label>
            <input
              type="text"
              id="billNumber"
              value={formData.billNumber}
              onChange={(e) =>
                setFormData({ ...formData, billNumber: e.target.value })
              }
            />
          </div>

          <div className="form-field">
            <label htmlFor="quantity">Quantity:</label>
            <input
              type="number"
              id="quantity"
              value={formData.quantity}
              onChange={(e) =>
                setFormData({ ...formData, quantity: e.target.value })
              }
            />
          </div>

          <div className="form-field">
            <label htmlFor="rate">Rate:</label>
            <input
              type="number"
              id="rate"
              value={formData.rate}
              onChange={(e) =>
                setFormData({ ...formData, rate: e.target.value })
              }
            />
          </div>

          <div className="form-field">
            <label htmlFor="tax">Tax:</label>
            <input
              type="number"
              id="tax"
              value={formData.tax}
              onChange={(e) =>
                setFormData({ ...formData, tax: e.target.value })
              }
            />
          </div>

          <div className="form-field">
            <label htmlFor="transportation">Transportation / Other:</label>
            <input
              type="number"
              id="transportation"
              value={formData.transportation}
              onChange={(e) =>
                setFormData({ ...formData, transportation: e.target.value })
              }
            />
          </div>

          <div className="form-field">
            <label>Amount:</label>
            <input type="text" id="amount" value={formData.amount} readOnly />
          </div>

          <div className="form-field">
            <label htmlFor="identification">Identification:</label>
            <input
              type="text"
              id="identification"
              value={formData.identification}
              onChange={(e) =>
                setFormData({ ...formData, identification: e.target.value })
              }
            />
          </div>

          <div className="form-field">
            <label htmlFor="condition">Condition:</label>
            <select
              id="condition"
              value={formData.condition}
              onChange={(e) =>
                setFormData({ ...formData, condition: e.target.value })
              }
            >
              <option value="">Select Condition</option>
              <option value="Good Condition">Good Condition</option>
              <option value="Running">Running</option>
              <option value="Condemned">Condemned</option>
            </select>
          </div>

        
        </>
      )} */}
    </div>
  )
}

export default MainForm
