import React from 'react'
import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Homepage from "./Pages/HomePage/Homepage"
import LoginUser from "./Pages/LoginUser/LoginUser"
import ParentComponent from './Components/ParentComponent/ParentComponent'
import MainForm from './Components/MainComponent/MainForm'
import AssetList from './Pages/AssetList/AssetList'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/login" element={<LoginUser />} />
        <Route path="/assets-register" element={<ParentComponent />} />
        <Route path="/main-form" element={<MainForm />} />
        <Route path="/assets" element={<AssetList />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App