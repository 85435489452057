// LoginPage.js

import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './LoginUser.css'

const LoginPage = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate() // Use useNavigate instead of useHistory

  const handleLogin = () => {
    // Check if the entered username and password match the expected values
    if (username === 'afpromain' && password === '123456@') {
      // Redirect to the AssetsRegister page on successful login
      navigate('/assets-register')
    } else {
      setError('Invalid username or password')
    }
  }

  return (
    // <div className="login-page">
    //   <h2>Login</h2>
    //   <div className="login-form">
    //     <label htmlFor="username">Username:</label>
    //     <input
    //       type="text"
    //       id="username"
    //       value={username}
    //       onChange={(e) => setUsername(e.target.value)}
    //     />

    //     <label htmlFor="password">Password:</label>
    //     <input
    //       type="password"
    //       id="password"
    //       value={password}
    //       onChange={(e) => setPassword(e.target.value)}
    //     />

    //     <button onClick={handleLogin}>Login</button>

    //     {error && <p className="error-message">{error}</p>}
    //   </div>
    // </div>
    <div className="login-box">
      <div className="container ">
        <div className="row justify-content-center  ">
          <div className="col-md-5 d-block m-auto login-form ">
            <h2 className="text-login pb-4">Login</h2>

            <form onSubmit={handleLogin}>
              {/* <!-- Email Field --> */}
              <label htmlFor="username">Username:</label>
              <div className="form-group">
                <input
                  type="text"
                  id="username"
                  className="form-control"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              {/* <!-- Password Field --> */}
              <div className="form-group">
                <label htmlFor="password">Password:</label>
                <input
                  type="password"
                  id="password"
                  className="form-control"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {/* <!-- Submit Button --> */}
              <div className="form-group">
                <button className="btn login-btn  " onClick={handleLogin}>
                  Login
                </button>
              </div>
              {error && <p className="error-message">{error}</p>}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
