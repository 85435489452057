// ParentComponent.js

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import MainForm from '../MainComponent/MainForm';

const ParentComponent = () => {
  return (
    <div>
      <Routes>
        <Route path="/main-form" element={<MainForm />} />
        <Route path="/" element={<Navigate to="/main-form" />} />
      </Routes>
    </div>
  );
};

export default ParentComponent;
